//
// user.scss
// Use this to write your custom SCSS
//

// @import "user/homepage";
// @import "user/shop";

// Overide default
.btn-dark:hover {
  background-color: $primary;
  border-color: $primary;
}

// .nav-link:hover {
//   text-decoration: underline;
//   text-underline-offset: 5px;
//   text-decoration-color: $accent;
// }

.btn-outline-secondary:hover {
  color: #fff !important;
}

.link-dark:hover {
  color: $primary  !important;
}

// Custom utillities
.img-decoration {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    inset: 10px -10px -10px 10px;
    background-color: $light;
  }

  img {
    z-index: 10;
    position: relative;
  }
}

.img-cover {
  padding-bottom: 65%;
  position: relative;

  img {
    position: absolute;
    inset: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

// Navbar
body {
  padding-top: 0 !important;
}

.custom-navbar:not(.navbar-stuck) {
  @include media-breakpoint-down(md) {
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    z-index: 300;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.navbar-stuck {
  max-width: 100%;
  padding: 0;
}

.navbar-nav {
  .dropdown-item {
    font-size: 1rem;
  }

  @include media-breakpoint-up(lg) {
    .dropdown-menu {
      box-shadow: 0 0 12px #00000025 !important;
    }
  }

  .dropdown-toggle .ci-caret {
    font-size: 0.45rem;
    line-height: 1.125rem;
    vertical-align: middle;
    margin-left: 0.5rem;
  }
}

// Footer
footer .link-light:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.footer-contact-details a:hover {
  color: #ccc !important;
  text-decoration: none;
}

.divide-x>*+* {
  border-left: 1px solid #ccc;
}

.divide-x-2>*+* {
  @include media-breakpoint-up(lg) {
    .link-dark {
      position: relative;

      &:after {
        content: "-";
        position: absolute;
        left: -1.2rem;
      }
    }
  }
}

.btn-secondary {
  color: white !important;
}

.form-check-input,
.form-check-label {
  cursor: pointer;
}

.navbar-bg {
  background: none;
}

.navbar-stuck {
  .navback-bg {
    background-color: white;
  }
}