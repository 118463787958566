table {
    width: 100%;
    margin-bottom: 1rem;
    color: #484848;
    vertical-align: top;
    border-color: #e3e9ef;
    border: 0px;
  }
  td{
    padding: 0.75rem 0.75rem;
  }
  table>tbody>:not(:first-child) {
    border-top: 2px solid currentColor;
  }

  table>tbody>:first-child {
    color: #2d2d2d !important;
  }

  table>tbody {
    vertical-align: inherit;
  }